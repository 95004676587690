<template>
  <become-affiliate-container />
</template>

<script>
  import BecomeAffiliateContainer from './become-affiliate.container.vue';
  export default {
    name: 'BecomeAffiliateView',
    components: {
      BecomeAffiliateContainer,
    },
  };
</script>
